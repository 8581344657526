import { createAsyncThunk } from '@reduxjs/toolkit'
import { apollo } from 'api'
import { ADD_SURVEY, SURVEY, SURVEYS, SURVEY_DEACTIVATE, SURVEY_EDIT } from 'requests/Surveys'
import { RootState } from 'store'
import { setSelectedLocations } from 'store/accountsSlice/locations'
import { retrieveLocations } from 'store/accountsSlice/locations/dispatchers.locations'
import { SurveyAddCreatedResponse, SurveyAddInputTypes, SurveyDataTypes, SurveyEditResponse, SurveyListTypes } from './surveyTypes'

export const addSurvey = createAsyncThunk<SurveyAddCreatedResponse, SurveyAddInputTypes>(
  'surveys/addSurvey',
  async (surveyInput: SurveyAddInputTypes, thunkApi) => {
    const { surveyName, theme, isAllowIncomplete, attributes, isAllLocations, locationTracking, locations, isForm, requiredEntity, uniqueSubmission } = surveyInput
    const state = thunkApi.getState() as RootState
    if (isAllLocations) {
      thunkApi.dispatch(retrieveLocations({ isAll: true }))
    } else {
      thunkApi.dispatch(setSelectedLocations(locations))
    }
    const locationValue = locationTracking === 'code' ? locations?.map((location) => location.value) : []
    const { data } = await apollo.mutate({
      mutation: ADD_SURVEY,
      variables: {
        input: { surveyName, theme, isAllowIncomplete, isAllLocations, attributes, locationTracking, locations: locationValue, isForm, requiredEntity, isUniqueSubmission: uniqueSubmission }
      }
    })

    if (data && !state.locations.locations.loading) {
      return data
    }
  }
)

export const syncSurveyQuestions = createAsyncThunk<SurveyEditResponse, SurveyAddInputTypes>(
  'surveys/syncSurveyQuestions',
  async (surveyQuestions: SurveyAddInputTypes, thunkApi) => {
    const currentState = thunkApi.getState() as RootState
    const { id } = currentState.surveys.surveySetup
    const { data } = await apollo.mutate({
      mutation: SURVEY_EDIT,
      variables: {
        input: { id, definition: JSON.stringify(surveyQuestions) }
      }
    })

    if (data) {
      return data
    }
  }
)

export const deactivateSurvey = createAsyncThunk(
  'surveys/deactivateSurvey',
  async (id : string, thunkApi) => {
    const { data } = await apollo.mutate({
      mutation: SURVEY_DEACTIVATE,
      variables: {
        input: { id }
      }
    })

    if (data) {
      return data
    }
  }
)

export const retrieveSurveyList = createAsyncThunk<SurveyListTypes, {isActive: boolean, type: string, refetch: boolean}>(
  'surveys/retrieveSurveyList',
  async ({ isActive, type, refetch }) => {
    const { data } = await apollo.query({
      query: SURVEYS,
      variables: {
        isActive,
        type
      }
    })

    if (data) {
      return data
    }
  }
)

export const retrieveSurvey = createAsyncThunk<SurveyDataTypes, string>(
  'surveys/retrieveSurvey',
  async (id) => {
    const { data } = await apollo.query({
      query: SURVEY,
      variables: {
        id
      }
    })
    if (data) {
      return data
    }
  }
)
