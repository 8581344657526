import { ChangeEvent, Dispatch, SetStateAction, createRef, useEffect, useState } from 'react'
import { FORM_THEMES, SURVEY_THEMES } from '../Themes/themes_constants'
import { isValidHexColor } from 'utils/common/isValidHexColor'
import { useDispatch, useSelector } from 'react-redux'
import { addSurvey } from 'store/surveysSlice/surveysDispatchers'
import { AppDispatch, RootState } from 'store'
import { retrieveLocations } from 'store/accountsSlice/locations/dispatchers.locations'
import _ from 'lodash'
import { setSelectedLocations } from 'store/accountsSlice/locations'
import { FORM } from 'pages/Settings/ManageForms/ManageForms'
import { SURVEY } from 'pages/Settings/ManageSurveys/ManageSurveys'
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client'

export interface SurveyDetailsTypes {
    surveyName: string
    surveyBrandColor: string
    locationTracking: string
    customPrivacyPolicyFooter: boolean
    customPrivacyPolicyText?: string
    uniqueSubmission?: boolean
    customPrivacyPolicyLink?: string
    savePartiallyCompleted: boolean
    askContactDetails: boolean
    locations: {
      value: string,
      text: string
    }[]
    selectedTheme: string
    isAllLocations: boolean
    requiredEntity: string
  }

export const useSurveyDetailsSetup = () => {
  const { data: locations } = useSelector((state: RootState) => state.locations.locations)
  const { id: surveyId } = useSelector((state: RootState) => state.surveys.surveySetup)
  const dispatch: AppDispatch = useDispatch()
  const [surveyDetails, setSurveyDetails] = useState<SurveyDetailsTypes>({
    surveyName: '',
    surveyBrandColor: '',
    locationTracking: 'disabled',
    customPrivacyPolicyFooter: false,
    uniqueSubmission: false,
    customPrivacyPolicyText: undefined,
    customPrivacyPolicyLink: undefined,
    savePartiallyCompleted: false,
    askContactDetails: false,
    locations: [],
    isAllLocations: false,
    selectedTheme: 'theme1',
    requiredEntity: 'None'
  })

  const handleChange = (key: keyof SurveyDetailsTypes, value: string | boolean) => {
    if (!locations) {
      dispatch(retrieveLocations({ first: 30 }))
    }
    setSurveyDetails(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const disableSetup = typeof surveyId === 'string'

  const disabledSaveButton = (surveyDetails.locationTracking !== 'disabled' && surveyDetails.locations.length === 0 && !surveyDetails.isAllLocations) || !surveyDetails.surveyName || (surveyDetails.customPrivacyPolicyFooter && (!surveyDetails.customPrivacyPolicyText || !surveyDetails.customPrivacyPolicyLink)) || (surveyDetails.surveyBrandColor !== '' && !isValidHexColor(surveyDetails.surveyBrandColor)) || disableSetup

  return { surveyDetails, handleChange, disabledSaveButton, setSurveyDetails, disableSetup }
}

export const useSaveDetails = ({ surveyDetails, surveyType } : { surveyDetails: SurveyDetailsTypes, surveyType: string }) => {
  const dispatch: AppDispatch = useDispatch()

  const { loading: isSavingDetails } = useSelector((state: RootState) => state.surveys.surveySetup)

  const handleSaveDetails = async () => {
    dispatch(setSelectedLocations({}))
    const themes: any[] = surveyType === SURVEY ? SURVEY_THEMES : FORM_THEMES
    const surveyTheme = themes.find((theme) => theme.id === surveyDetails.selectedTheme)
    if (surveyTheme && surveyDetails.surveyBrandColor) {
      surveyTheme.theme_json.cssVariables['--sjs-primary-backcolor'] = surveyDetails.surveyBrandColor
    }

    const hasCustomPrivacyPolicy = surveyDetails.customPrivacyPolicyFooter

    const attributes = {
      privacyPolicy: {
        text: surveyDetails.customPrivacyPolicyText,
        link: surveyDetails.customPrivacyPolicyLink
      }
    }

    const surveyInput = {
      surveyName: surveyDetails.surveyName,
      theme: JSON.stringify(surveyTheme),
      isAllowIncomplete: surveyDetails.savePartiallyCompleted,
      askContactDetails: surveyDetails.askContactDetails,
      isAllLocations: surveyDetails.isAllLocations,
      uniqueSubmission: surveyDetails.uniqueSubmission,
      attributes: hasCustomPrivacyPolicy ? JSON.stringify(attributes) : undefined,
      locationTracking: surveyDetails.locationTracking,
      locations: surveyDetails.locations,
      isForm: surveyType === FORM && true,
      requiredEntity: surveyDetails.requiredEntity
    }
    await dispatch(addSurvey(surveyInput))
  }

  return { handleSaveDetails, isSavingDetails }
}

export const useGetLocations = (surveyDetails: SurveyDetailsTypes, setSurveyDetails: Dispatch<SetStateAction<SurveyDetailsTypes>>, getAllLocations: LazyQueryExecFunction<any, OperationVariables>) => {
  const { data: locations, loading: loadingLocations } = useSelector((state: RootState) => state.locations.locations)
  const [searchLocation, setSearchLocation] = useState('')
  const [values, setValues] = useState<string[]>([])
  const dispatch: AppDispatch = useDispatch()

  const input = createRef()
  const onAdd = (optionValue: string) => {
    const updatedValue = [...values, optionValue]
    setValues(updatedValue)
    const location = locations?.locations.edges.find((location) => location.node.name === optionValue)

    setSurveyDetails(prevState => {
      return {
        ...prevState,
        locations: [...prevState.locations, { text: optionValue, value: location?.node.uuid || '' }]
      }
    }
    )
  }

  const onRemove = (optionValue: string) => {
    const updatedValue = values.filter((val) => val !== optionValue)
    setValues(updatedValue)
    setSurveyDetails(prevState => {
      return {
        ...prevState,
        locations: prevState.locations.filter((location) => location.text !== optionValue)
      }
    }
    )
  }

  const clearSelection = () => {
    setValues([])
    setSurveyDetails(prevState => ({
      ...prevState,
      locations: []
    }))
  }
  const handleSearch = _.debounce((e: ChangeEvent<HTMLInputElement>) => {
    setSearchLocation(e.target.value)
  }, 1000)

  useEffect(() => {
    if (surveyDetails.locationTracking !== 'disabled') {
      dispatch(retrieveLocations({ searchParam: searchLocation, first: 30 }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLocation])

  useEffect(() => {
    if (surveyDetails.isAllLocations) {
      setValues([])
      getAllLocations()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDetails.isAllLocations])

  return { onAdd, onRemove, clearSelection, locations, loadingLocations, handleSearch, input, values, setValues }
}
